import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning-birtan'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'
  function ageCalculation (dateofBirth) {
    const today = new Date()
    const birthDate = new Date(dateofBirth)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
    }
    return age
  }
const exportPdfDetails = async (baseUrl, uri = '/report-heading/detail', orgId, reportTitle, data, vm, trainees, trainers) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
        const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
        // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
        const pdfContent = [{
            columns: reportHeadData.reportHeadColumn
          },
          { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
          { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
          { text: vm.getOfficeName(data.office_id), style: 'address', alignment: 'center' },
          { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const bodySeperate = [
          [
            { text: vm.$t('elearning_iabm.circular_memo_no'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: data.circular_memo_no, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.fiscal_year_bn : data.fiscal_year, style: 'td', alignment: 'left' }
          ]
        ]
        if (Store.state.Auth.activeRoleId === 1 || Store.state.Auth.authUser.org_id === 1 || Store.state.Auth.authUser.org_id === 12) {
          bodySeperate.push([
            { text: vm.$t('globalTrans.organization'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.org_bn : data.org, style: 'td', alignment: 'left' },
            {},
            {},
            {}
          ]
          )
        }
        bodySeperate.push([
          { text: vm.$t('elearning_config.office_type'), style: 'th', alignment: 'left' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: vm.$i18n.locale === 'bn' ? data.office_type_bn : data.office_type, style: 'td', alignment: 'left' },
          { text: vm.$t('globalTrans.office'), style: 'th', alignment: 'left' },
          { text: ':', style: 'td', alignment: 'center' },
          { text: vm.$i18n.locale === 'bn' ? data.office_bn : data.office, style: 'td', alignment: 'left' }
        ]
        )
        bodySeperate.push(
          [
            { text: vm.$t('elearning_config.training_type'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.training_type_bn : data.training_type, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_config.training_category'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.training_category_bn : data.training_category, style: 'td', alignment: 'left' }
          ]
          )
        bodySeperate.push(
          [
            { text: vm.$t('elearning_config.training_title'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.$i18n.locale === 'bn' ? data.training_title_bn : data.training_title, style: 'td', alignment: 'left' },
            { text: vm.$t('elearning_tpm.trainee_type'), style: 'th', alignment: 'left' },
            { text: ':', style: 'td', alignment: 'center' },
            { text: vm.getOtherTrainee(vm.formData.other_trainee_type_id), style: 'td', alignment: 'left' }
          ]
          )
        pdfContent.push(
          {
            table: {
              style: 'tableData',
              headerRows: 0,
              widths: ['18%', '3%', '29%', '18%', '3%', '29%'],
              body: bodySeperate
            },
            layout: {
              hLineWidth: function (i, node) {
                return 0
              },
              vLineWidth: function (i, node) {
                return 0
              }
            }
          }
        )
        pdfContent.push({ text: vm.$t('elearning_config.trainee') + ' ' + vm.$t('globalTrans.details'), style: 'hh', alignment: 'center' })
        // option
          let allRows = []
          allRows = [
            [
              { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
              { text: vm.$t('globalTrans.name') + '\n' + vm.$t('admission_form.mobile_no'), style: 'th', alignment: 'center' },
              { text: vm.$t('elearning_tim.name_of_father_husband'), style: 'th', alignment: 'center' },
              { text: vm.$t('globalTrans.organization'), style: 'th', alignment: 'center' },
              { text: vm.$t('globalTrans.profession'), style: 'th', alignment: 'center' },
              { text: vm.$t('elearning_accommodation.address'), style: 'th', alignment: 'center' },
              { text: vm.$t('elearning_tim.monthly_income'), style: 'th', alignment: 'center' },
              { text: vm.$t('admission_form.age'), style: 'th', alignment: 'center' }
            ]
          ]
          // table body
          trainees.filter((data, optionIndex) => {
            let monthlyIncome = ''
            if (data.monthly_income > 0) {
              monthlyIncome = vm.$n(data.monthly_income)
            }
            let age = 0
            if (data.age > 0) {
              age = vm.$n(data.age)
            } else {
              age = vm.$n(ageCalculation(data.date_of_birth))
            }
            const rowItem = [
              { text: vm.$n(optionIndex + 1), style: 'td', alignment: 'center' },
              { text: (vm.$i18n.locale === 'bn' ? data.name_bn : data.name) + '\n' + ((vm.$i18n.locale === 'bn' ? '০' : '0') + vm.$n(data.mobile, { useGrouping: false })), style: 'td', alignment: 'center' },
              { text: vm.$i18n.locale === 'bn' ? data.name_of_father_husband_bn : data.name_of_father_husband, style: 'td', alignment: 'center' },
              { text: vm.$i18n.locale === 'bn' ? data.other_org_bn : data.other_org, style: 'td', alignment: 'center' },
              { text: vm.$i18n.locale === 'bn' ? data.other_designation_bn : data.designation, style: 'td', alignment: 'center' },
              { text: data.other_address, style: 'td', alignment: 'center' },
              { text: monthlyIncome, style: 'td', alignment: 'center' },
              { text: age, style: 'td', alignment: 'center' }
            ]
            allRows.push(rowItem)
          })
          pdfContent.push({ text: '', style: 'tableMargin' })
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['8%', '16%', '12%', '12%', '12%', '15%', '10%', '10%'],
              body: allRows
            }
          })
        pdfContent.push({ text: vm.$t('elearning_config.trainer') + ' ' + vm.$t('globalTrans.details'), style: 'hh', alignment: 'center' })
        // option
          let allRowsTrainer = []
          allRowsTrainer = [
            [
              { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center' },
              { text: vm.$t('globalTrans.name') + '\n' + vm.$t('admission_form.mobile_no'), style: 'th', alignment: 'center' },
              { text: vm.$t('elearning_tim.name_of_father_husband'), style: 'th', alignment: 'center' },
              { text: vm.$t('globalTrans.organization'), style: 'th', alignment: 'center' },
              { text: vm.$t('globalTrans.designation'), style: 'th', alignment: 'center' },
              { text: vm.$t('elearning_accommodation.address'), style: 'th', alignment: 'center' },
              { text: vm.$t('admission_form.age'), style: 'th', alignment: 'center' }
            ]
          ]
          if (trainers.length === 0) {
            const noTrainer = [
                { text: vm.$t('globalTrans.noDataFound'), style: 'th', alignment: 'center', colSpan: 7 },
                {},
                {},
                {},
                {},
                {},
                {}
            ]
            allRowsTrainer.push(noTrainer)
          } else {
            trainers.filter((data, optionIndex) => {
              let age = 0
              if (data.age > 0) {
                age = vm.$n(data.age)
              } else {
                age = vm.$n(ageCalculation(data.date_of_birth))
              }
              const rowItem = [
                { text: vm.$n(optionIndex + 1), style: 'td', alignment: 'center' },
                { text: (vm.$i18n.locale === 'bn' ? data.name_bn : data.name) + '\n' + ((vm.$i18n.locale === 'bn' ? '০' : '0') + vm.$n(data.mobile, { useGrouping: false })), style: 'td', alignment: 'center' },
                { text: vm.$i18n.locale === 'bn' ? data.name_of_father_husband_bn : data.name_of_father_husband, style: 'td', alignment: 'center' },
                { text: vm.$i18n.locale === 'bn' ? data.other_org_bn : data.other_org, style: 'td', alignment: 'center' },
                { text: vm.$i18n.locale === 'bn' ? data.other_designation_bn : data.designation, style: 'td', alignment: 'center' },
                { text: data.address, style: 'td', alignment: 'center' },
                { text: age, style: 'td', alignment: 'center' }
              ]
              allRowsTrainer.push(rowItem)
            })
          }
          pdfContent.push({ text: '', style: 'tableMargin' })
          pdfContent.push({
            table: {
              headerRows: 1,
              widths: ['8%', '16%', '16%', '16%', '16%', '18%', '10%'],
              body: allRowsTrainer
            }
          })
        var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: (i18n === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          td: {
            fontSize: (i18n === 'bn') ? 10 : 9,
            margin: [3, 3, 3, 3]
          },
          header: {
            fontSize: 16,
            bold: true,
            margin: [0, 0, 0, 0]
        },
        org: {
            fontSize: 13,
            bold: true,
            margin: [5, -25, 5, 5]
        },
        address: {
            fontSize: 11,
            margin: [0, 0, 0, 5]
        },
        hh: {
            fontSize: 13,
            bold: true,
            margin: [10, 10, 25, 20]
        },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          fertilizer: {
            fontSize: 10,
            margin: [10, 10, 0, 20]
          },
          headerPort1: {
            fontSize: 10,
            margin: [0, 20, 0, 0]
          },
          headerPort: {
            fontSize: 10,
            margin: [0, 4, 0, 15]
          },
          krishi: {
            margin: [0, -5, 0, 15],
            alignment: 'center'
          },
          header3: {
            fontSize: 9,
            margin: [0, 0, 0, 4]
          },
          tableSubHead: {
            margin: [0, 5, 0, 15]
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
